.compact-header {
  h1 {
    font-size: 24px;
    line-height: 28px;
    color: $darker-text-color;
    font-weight: 500;
    margin-bottom: 20px;
    padding: 0 10px;
    word-wrap: break-word;

    @media screen and (max-width: 740px) {
      text-align: center;
      padding: 20px 10px 0;
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    small {
      font-weight: 400;
      color: $secondary-text-color;
    }

    img {
      display: inline-block;
      margin-bottom: -5px;
      margin-right: 15px;
      width: 36px;
      height: 36px;
    }
  }
}
