.app-body {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.button {
  background-color: $ui-highlight-color;
  border: 10px none;
  border-radius: 4px;
  box-sizing: border-box;
  color: $primary-text-color;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  height: 36px;
  letter-spacing: 0;
  line-height: 36px;
  overflow: hidden;
  padding: 0 16px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  text-overflow: ellipsis;
  transition: all 100ms ease-in;
  white-space: nowrap;
  width: auto;

  &:active,
  &:focus,
  &:hover {
    background-color: lighten($ui-highlight-color, 10%);
    transition: all 200ms ease-out;
  }

  &--destructive {
    transition: none;

    &:active,
    &:focus,
    &:hover {
      background-color: $error-red;
      transition: none;
    }
  }

  &:disabled,
  &.disabled {
    background-color: $ui-primary-color;
    cursor: default;
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &::-moz-focus-inner,
  &:focus,
  &:active {
    outline: 0 !important;
  }

  &.button-primary,
  &.button-alternative,
  &.button-secondary,
  &.button-alternative-2 {
    font-size: 16px;
    line-height: 36px;
    height: auto;
    text-transform: none;
    padding: 4px 16px;
  }

  &.button-alternative {
    color: $inverted-text-color;
    background: $ui-primary-color;

    &:active,
    &:focus,
    &:hover {
      background-color: lighten($ui-primary-color, 4%);
    }
  }

  &.button-alternative-2 {
    background: $ui-base-lighter-color;

    &:active,
    &:focus,
    &:hover {
      background-color: lighten($ui-base-lighter-color, 4%);
    }
  }

  &.button-secondary {
    color: $darker-text-color;
    background: transparent;
    padding: 3px 15px;
    border: 1px solid $ui-primary-color;

    &:active,
    &:focus,
    &:hover {
      border-color: lighten($ui-primary-color, 4%);
      color: lighten($darker-text-color, 4%);
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  &.button--block {
    display: block;
    width: 100%;
  }
}

.column__wrapper {
  display: flex;
  flex: 1 1 auto;
  position: relative;
}

.icon-button {
  display: inline-block;
  padding: 0;
  color: $action-button-color;
  border: none;
  background: transparent;
  cursor: pointer;
  transition: color 100ms ease-in;

  &:hover,
  &:active,
  &:focus {
    color: lighten($action-button-color, 7%);
    transition: color 200ms ease-out;
  }

  &.disabled {
    color: darken($action-button-color, 13%);
    cursor: default;
  }

  &.active {
    color: $highlight-text-color;
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &::-moz-focus-inner,
  &:focus,
  &:active {
    outline: 0 !important;
  }

  &.inverted {
    color: $lighter-text-color;

    &:hover,
    &:active,
    &:focus {
      color: darken($lighter-text-color, 7%);
    }

    &.disabled {
      color: lighten($lighter-text-color, 7%);
    }

    &.active {
      color: $highlight-text-color;

      &.disabled {
        color: lighten($highlight-text-color, 13%);
      }
    }
  }

  &.overlayed {
    box-sizing: content-box;
    background: rgba($base-overlay-background, 0.6);
    color: rgba($primary-text-color, 0.7);
    border-radius: 4px;
    padding: 2px;

    &:hover {
      background: rgba($base-overlay-background, 0.9);
    }
  }
}

.text-icon-button {
  color: $lighter-text-color;
  border: none;
  background: transparent;
  cursor: pointer;
  font-weight: 600;
  font-size: 11px;
  padding: 0 3px;
  line-height: 27px;
  outline: 0;
  transition: color 100ms ease-in;

  &:hover,
  &:active,
  &:focus {
    color: darken($lighter-text-color, 7%);
    transition: color 200ms ease-out;
  }

  &.disabled {
    color: lighten($lighter-text-color, 20%);
    cursor: default;
  }

  &.active {
    color: $highlight-text-color;
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &::-moz-focus-inner,
  &:focus,
  &:active {
    outline: 0 !important;
  }
}

.dropdown-menu {
  position: absolute;
}

.invisible {
  font-size: 0;
  line-height: 0;
  display: inline-block;
  width: 0;
  height: 0;
  position: absolute;

  img,
  svg {
    margin: 0 !important;
    border: 0 !important;
    padding: 0 !important;
    width: 0 !important;
    height: 0 !important;
  }
}

.ellipsis {
  &::after {
    content: "…";
  }
}

.compose-form {
  padding: 10px;

  .compose-form__warning {
    color: $inverted-text-color;
    margin-bottom: 10px;
    background: $ui-primary-color;
    box-shadow: 0 2px 6px rgba($base-shadow-color, 0.3);
    padding: 8px 10px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 400;

    strong {
      color: $inverted-text-color;
      font-weight: 500;

      @each $lang in $cjk-langs {
        &:lang(#{$lang}) {
          font-weight: 700;
        }
      }
    }

    a {
      color: $lighter-text-color;
      font-weight: 500;
      text-decoration: underline;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
    }
  }

  .compose-form__autosuggest-wrapper {
    position: relative;

    .emoji-picker-dropdown {
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }

  .autosuggest-textarea,
  .spoiler-input {
    position: relative;
  }

  .spoiler-input {
    height: 0;
    transform-origin: bottom;
    opacity: 0.0;

    &.spoiler-input--visible {
      height: 47px;
      opacity: 1.0;
    }
  }

  .autosuggest-textarea__textarea,
  .spoiler-input__input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    color: $inverted-text-color;
    background: $simple-background-color;
    padding: 10px;
    font-family: inherit;
    font-size: 14px;
    resize: vertical;
    border: 0;
    outline: 0;

    &:focus {
      outline: 0;
    }

    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
  }

  .spoiler-input__input {
    border-radius: 4px;
  }

  .autosuggest-textarea__textarea {
    min-height: 100px;
    border-radius: 4px 4px 0 0;
    padding-bottom: 0;
    padding-right: 10px + 22px;
    resize: none;
    scrollbar-color: initial;

    &::-webkit-scrollbar {
      all: unset;
    }

    @media screen and (max-width: 600px) {
      height: 100px !important; // prevent auto-resize textarea
      resize: vertical;
    }
  }

  .autosuggest-textarea__suggestions {
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 99;
    box-shadow: 4px 4px 6px rgba($base-shadow-color, 0.4);
    background: $ui-secondary-color;
    border-radius: 0 0 4px 4px;
    color: $inverted-text-color;
    font-size: 14px;
    padding: 6px;

    &.autosuggest-textarea__suggestions--visible {
      display: block;
    }
  }

  .autosuggest-textarea__suggestions__item {
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;

    &:hover,
    &:focus,
    &:active,
    &.selected {
      background: darken($ui-secondary-color, 10%);
    }
  }

  .autosuggest-account,
  .autosuggest-emoji {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    line-height: 18px;
    font-size: 14px;
  }

  .autosuggest-account-icon,
  .autosuggest-emoji img {
    display: block;
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }

  .autosuggest-account .display-name__account {
    color: $lighter-text-color;
  }

  .compose-form__modifiers {
    color: $inverted-text-color;
    font-family: inherit;
    font-size: 14px;
    background: $simple-background-color;

    .compose-form__upload-wrapper {
      overflow: hidden;
    }

    .compose-form__uploads-wrapper {
      display: flex;
      flex-direction: row;
      padding: 5px;
      flex-wrap: wrap;
    }

    .compose-form__upload {
      flex: 1 1 0;
      min-width: 40%;
      margin: 5px;

      &__actions {
        background: linear-gradient(180deg, rgba($base-shadow-color, 0.8) 0, rgba($base-shadow-color, 0.35) 80%, transparent);
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        opacity: 0;
        transition: opacity .1s ease;

        .icon-button {
          flex: 0 1 auto;
          color: $secondary-text-color;
          font-size: 14px;
          font-weight: 500;
          padding: 10px;
          font-family: inherit;

          &:hover,
          &:focus,
          &:active {
            color: lighten($secondary-text-color, 7%);
          }
        }

        &.active {
          opacity: 1;
        }
      }

      &-description {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        right: 0;
        box-sizing: border-box;
        background: linear-gradient(0deg, rgba($base-shadow-color, 0.8) 0, rgba($base-shadow-color, 0.35) 80%, transparent);
        padding: 10px;
        opacity: 0;
        transition: opacity .1s ease;

        textarea {
          background: transparent;
          color: $secondary-text-color;
          border: 0;
          padding: 0;
          margin: 0;
          width: 100%;
          font-family: inherit;
          font-size: 14px;
          font-weight: 500;

          &:focus {
            color: $white;
          }

          &::placeholder {
            opacity: 0.75;
            color: $secondary-text-color;
          }
        }

        &.active {
          opacity: 1;
        }
      }
    }

    .compose-form__upload-thumbnail {
      border-radius: 4px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 140px;
      width: 100%;
      overflow: hidden;
    }
  }

  .compose-form__buttons-wrapper {
    padding: 10px;
    background: darken($simple-background-color, 8%);
    border-radius: 0 0 4px 4px;
    display: flex;
    justify-content: space-between;

    .compose-form__buttons {
      display: flex;

      .compose-form__upload-button-icon {
        line-height: 27px;
      }

      .compose-form__sensitive-button {
        display: none;

        &.compose-form__sensitive-button--visible {
          display: block;
        }

        .compose-form__sensitive-button__icon {
          line-height: 27px;
        }
      }
    }

    .icon-button {
      box-sizing: content-box;
      padding: 0 3px;
    }

    .character-counter__wrapper {
      align-self: center;
      margin-right: 4px;

      .character-counter {
        cursor: default;
        font-family: $font-sans-serif, sans-serif;
        font-size: 14px;
        font-weight: 600;
        color: $lighter-text-color;

        &.character-counter--over {
          color: $warning-red;
        }
      }
    }
  }

  .compose-form__publish {
    display: flex;
    justify-content: flex-end;
    min-width: 0;

    .compose-form__publish-button-wrapper {
      overflow: hidden;
      padding-top: 10px;
    }
  }
}

.no-reduce-motion .spoiler-input {
  transition: height 0.4s ease, opacity 0.4s ease;
}

.emojione {
  font-size: inherit;
  vertical-align: middle;
  object-fit: contain;
  margin: -.2ex .15em .2ex;
  width: 16px;
  height: 16px;

  img {
    width: auto;
  }
}

.reply-indicator {
  border-radius: 4px;
  margin-bottom: 10px;
  background: $ui-primary-color;
  padding: 10px;
}

.reply-indicator__header {
  margin-bottom: 5px;
  overflow: hidden;
}

.reply-indicator__cancel {
  float: right;
  line-height: 24px;
}

.reply-indicator__display-name {
  color: $inverted-text-color;
  display: block;
  max-width: 100%;
  line-height: 24px;
  overflow: hidden;
  padding-right: 25px;
  text-decoration: none;
}

.reply-indicator__display-avatar {
  float: left;
  margin-right: 5px;
}

.status__content--with-action {
  cursor: pointer;
}

.status__content,
.reply-indicator__content {
  position: relative;
  font-size: 15px;
  line-height: 20px;
  word-wrap: break-word;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 2px;
  color: $primary-text-color;

  &:focus {
    outline: 0;
  }

  &.status__content--with-spoiler {
    white-space: normal;

    .status__content__text {
      white-space: pre-wrap;
    }
  }

  .emojione {
    width: 20px;
    height: 20px;
    margin: -3px 0 0;
  }

  p {
    margin-bottom: 20px;
    white-space: pre-wrap;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $secondary-text-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;

      .fa {
        color: lighten($dark-text-color, 7%);
      }
    }

    &.mention {
      &:hover {
        text-decoration: none;

        span {
          text-decoration: underline;
        }
      }
    }

    .fa {
      color: $dark-text-color;
    }
  }

  .status__content__spoiler-link {
    background: $action-button-color;

    &:hover {
      background: lighten($action-button-color, 7%);
      text-decoration: none;
    }

    &::-moz-focus-inner {
      border: 0;
    }

    &::-moz-focus-inner,
    &:focus,
    &:active {
      outline: 0 !important;
    }
  }

  .status__content__text {
    display: none;

    &.status__content__text--visible {
      display: block;
    }
  }
}

.status__content.status__content--collapsed {
  max-height: 20px * 15; // 15 lines is roughly above 500 characters
}

.status__content__read-more-button {
  display: block;
  font-size: 15px;
  line-height: 20px;
  color: lighten($ui-highlight-color, 8%);
  border: 0;
  background: transparent;
  padding: 0;
  padding-top: 8px;

  &:hover,
  &:active {
    text-decoration: underline;
  }
}

.status__content__spoiler-link {
  display: inline-block;
  border-radius: 2px;
  background: transparent;
  border: 0;
  color: $inverted-text-color;
  font-weight: 700;
  font-size: 11px;
  padding: 0 6px;
  text-transform: uppercase;
  line-height: 20px;
  cursor: pointer;
  vertical-align: middle;
}

.status__wrapper--filtered {
  color: $dark-text-color;
  border: 0;
  font-size: inherit;
  text-align: center;
  line-height: inherit;
  margin: 0;
  padding: 15px;
  box-sizing: border-box;
  width: 100%;
  clear: both;
  border-bottom: 1px solid lighten($ui-base-color, 8%);
}

.status__prepend-icon-wrapper {
  left: -26px;
  position: absolute;
}

.focusable {
  &:focus {
    outline: 0;
    background: lighten($ui-base-color, 4%);

    .status.status-direct {
      background: lighten($ui-base-color, 12%);

      &.muted {
        background: transparent;
      }
    }

    .detailed-status,
    .detailed-status__action-bar {
      background: lighten($ui-base-color, 8%);
    }
  }
}

.status {
  padding: 8px 10px;
  padding-left: 68px;
  position: relative;
  min-height: 54px;
  border-bottom: 1px solid lighten($ui-base-color, 8%);
  cursor: default;

  @supports (-ms-overflow-style: -ms-autohiding-scrollbar) {
    // Add margin to avoid Edge auto-hiding scrollbar appearing over content.
    // On Edge 16 this is 16px and Edge <=15 it's 12px, so aim for 16px.
    padding-right: 26px; // 10px + 16px
  }

  @keyframes fade {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  opacity: 1;
  animation: fade 150ms linear;

  .video-player {
    margin-top: 8px;
  }

  &.status-direct:not(.read) {
    background: lighten($ui-base-color, 8%);
    border-bottom-color: lighten($ui-base-color, 12%);
  }

  &.light {
    .status__relative-time {
      color: $light-text-color;
    }

    .status__display-name {
      color: $inverted-text-color;
    }

    .display-name {
      strong {
        color: $inverted-text-color;
      }

      span {
        color: $light-text-color;
      }
    }

    .status__content {
      color: $inverted-text-color;

      a {
        color: $highlight-text-color;
      }

      a.status__content__spoiler-link {
        color: $primary-text-color;
        background: $ui-primary-color;

        &:hover {
          background: lighten($ui-primary-color, 8%);
        }
      }
    }
  }
}

.notification-favourite {
  .status.status-direct {
    background: transparent;

    .icon-button.disabled {
      color: lighten($action-button-color, 13%);
    }
  }
}

.status__relative-time,
.notification__relative_time {
  color: $dark-text-color;
  float: right;
  font-size: 14px;
}

.status__display-name {
  color: $dark-text-color;
}

.status__info .status__display-name {
  display: block;
  max-width: 100%;
  padding-right: 25px;
}

.status__info {
  font-size: 15px;
}

.status-check-box {
  border-bottom: 1px solid $ui-secondary-color;
  display: flex;

  .status-check-box__status {
    margin: 10px 0 10px 10px;
    flex: 1;

    .media-gallery {
      max-width: 250px;
    }

    .status__content {
      padding: 0;
      white-space: normal;
    }

    .video-player {
      margin-top: 8px;
      max-width: 250px;
    }

    .media-gallery__item-thumbnail {
      cursor: default;
    }
  }
}

.status-check-box-toggle {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 10px;
}

.status__prepend {
  margin-left: 68px;
  color: $dark-text-color;
  padding: 8px 0;
  padding-bottom: 2px;
  font-size: 14px;
  position: relative;

  .status__display-name strong {
    color: $dark-text-color;
  }

  > span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.status__action-bar {
  align-items: center;
  display: flex;
  margin-top: 8px;

  &__counter {
    display: inline-flex;
    margin-right: 11px;
    align-items: center;

    .status__action-bar-button {
      margin-right: 4px;
    }

    &__label {
      display: inline-block;
      width: 14px;
      font-size: 12px;
      font-weight: 500;
      color: $action-button-color;
    }
  }
}

.status__action-bar-button {
  margin-right: 18px;
}

.status__action-bar-dropdown {
  height: 23.15px;
  width: 23.15px;
}

.detailed-status__action-bar-dropdown {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.detailed-status {
  background: lighten($ui-base-color, 4%);
  padding: 14px 10px;

  &--flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    .status__content,
    .detailed-status__meta {
      flex: 100%;
    }
  }

  .status__content {
    font-size: 19px;
    line-height: 24px;

    .emojione {
      width: 24px;
      height: 24px;
      margin: -1px 0 0;
    }

    .status__content__spoiler-link {
      line-height: 24px;
      margin: -1px 0 0;
    }
  }

  .video-player {
    margin-top: 8px;
  }
}

.detailed-status__meta {
  margin-top: 15px;
  color: $dark-text-color;
  font-size: 14px;
  line-height: 18px;
}

.detailed-status__action-bar {
  background: lighten($ui-base-color, 4%);
  border-top: 1px solid lighten($ui-base-color, 8%);
  border-bottom: 1px solid lighten($ui-base-color, 8%);
  display: flex;
  flex-direction: row;
  padding: 10px 0;
}

.detailed-status__link {
  color: inherit;
  text-decoration: none;
}

.detailed-status__favorites,
.detailed-status__reblogs {
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  margin-left: 6px;
}

.reply-indicator__content {
  color: $inverted-text-color;
  font-size: 14px;

  a {
    color: $lighter-text-color;
  }
}

.domain {
  padding: 10px;
  border-bottom: 1px solid lighten($ui-base-color, 8%);

  .domain__domain-name {
    flex: 1 1 auto;
    display: block;
    color: $primary-text-color;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
  }
}

.domain__wrapper {
  display: flex;
}

.domain_buttons {
  height: 18px;
  padding: 10px;
  white-space: nowrap;
}

.account {
  padding: 10px;
  border-bottom: 1px solid lighten($ui-base-color, 8%);

  &.compact {
    padding: 0;
    border-bottom: 0;

    .account__avatar-wrapper {
      margin-left: 0;
    }
  }

  .account__display-name {
    flex: 1 1 auto;
    display: block;
    color: $darker-text-color;
    overflow: hidden;
    text-decoration: none;
    font-size: 14px;
  }
}

.account__wrapper {
  display: flex;
}

.account__avatar-wrapper {
  float: left;
  margin-left: 12px;
  margin-right: 12px;
}

.account__avatar {
  @include avatar-radius();
  position: relative;

  &-inline {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }

  &-composite {
    @include avatar-radius();
    overflow: hidden;

    & > div {
      @include avatar-radius();
      float: left;
      position: relative;
      box-sizing: border-box;
    }
  }
}

a .account__avatar {
  cursor: pointer;
}

.account__avatar-overlay {
  @include avatar-size(48px);

  &-base {
    @include avatar-radius();
    @include avatar-size(36px);
  }

  &-overlay {
    @include avatar-radius();
    @include avatar-size(24px);

    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
}

.account__relationship {
  height: 18px;
  padding: 10px;
  white-space: nowrap;
}

.account__disclaimer {
  padding: 10px;
  border-top: 1px solid lighten($ui-base-color, 8%);
  color: $dark-text-color;

  strong {
    font-weight: 500;

    @each $lang in $cjk-langs {
      &:lang(#{$lang}) {
        font-weight: 700;
      }
    }
  }

  a {
    font-weight: 500;
    color: inherit;
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }
}

.account__action-bar {
  border-top: 1px solid lighten($ui-base-color, 8%);
  border-bottom: 1px solid lighten($ui-base-color, 8%);
  line-height: 36px;
  overflow: hidden;
  flex: 0 0 auto;
  display: flex;
}

.account__action-bar-dropdown {
  padding: 10px;

  .icon-button {
    vertical-align: middle;
  }

  .dropdown--active {
    .dropdown__content.dropdown__right {
      left: 6px;
      right: initial;
    }

    &::after {
      bottom: initial;
      margin-left: 11px;
      margin-top: -7px;
      right: initial;
    }
  }
}

.account__action-bar-links {
  display: flex;
  flex: 1 1 auto;
  line-height: 18px;
  text-align: center;
}

.account__action-bar__tab {
  text-decoration: none;
  overflow: hidden;
  flex: 0 1 100%;
  border-right: 1px solid lighten($ui-base-color, 8%);
  padding: 10px 0;
  border-bottom: 4px solid transparent;

  &.active {
    border-bottom: 4px solid $ui-highlight-color;
  }

  & > span {
    display: block;
    text-transform: uppercase;
    font-size: 11px;
    color: $darker-text-color;
  }

  strong {
    display: block;
    font-size: 15px;
    font-weight: 500;
    color: $primary-text-color;

    @each $lang in $cjk-langs {
      &:lang(#{$lang}) {
        font-weight: 700;
      }
    }
  }
}

.account-authorize {
  padding: 14px 10px;

  .detailed-status__display-name {
    display: block;
    margin-bottom: 15px;
    overflow: hidden;
  }
}

.account-authorize__avatar {
  float: left;
  margin-right: 10px;
}

.status__display-name,
.status__relative-time,
.detailed-status__display-name,
.detailed-status__datetime,
.detailed-status__application,
.account__display-name {
  text-decoration: none;
}

.status__display-name,
.account__display-name {
  strong {
    color: $primary-text-color;
  }
}

.muted {
  .emojione {
    opacity: 0.5;
  }
}

.status__display-name,
.reply-indicator__display-name,
.detailed-status__display-name,
a.account__display-name {
  &:hover strong {
    text-decoration: underline;
  }
}

.account__display-name strong {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.detailed-status__application,
.detailed-status__datetime {
  color: inherit;
}

.detailed-status__display-name {
  color: $secondary-text-color;
  display: block;
  line-height: 24px;
  margin-bottom: 15px;
  overflow: hidden;

  strong,
  span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  strong {
    font-size: 16px;
    color: $primary-text-color;
  }
}

.detailed-status__display-avatar {
  float: left;
  margin-right: 10px;
}

.status__avatar {
  height: 48px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 48px;
}

.muted {
  .status__content p,
  .status__content a {
    color: $dark-text-color;
  }

  .status__display-name strong {
    color: $dark-text-color;
  }

  .status__avatar {
    opacity: 0.5;
  }

  a.status__content__spoiler-link {
    background: $ui-base-lighter-color;
    color: $inverted-text-color;

    &:hover {
      background: lighten($ui-base-lighter-color, 7%);
      text-decoration: none;
    }
  }
}

.notification__message {
  margin: 0 10px 0 68px;
  padding: 8px 0 0;
  cursor: default;
  color: $darker-text-color;
  font-size: 15px;
  line-height: 22px;
  position: relative;

  .fa {
    color: $highlight-text-color;
  }

  > span {
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.notification__favourite-icon-wrapper {
  left: -26px;
  position: absolute;

  .star-icon {
    color: $gold-star;
  }
}

.star-icon.active {
  color: $gold-star;
}

.notification__display-name {
  color: inherit;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    color: $primary-text-color;
    text-decoration: underline;
  }
}

.notification__relative_time {
  float: right;
}

.display-name {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.display-name__html {
  font-weight: 500;
}

.display-name__account {
  font-size: 14px;
}

.status__relative-time,
.detailed-status__datetime {
  &:hover {
    text-decoration: underline;
  }
}

.image-loader {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .image-loader__preview-canvas {
    max-width: $media-modal-media-max-width;
    max-height: $media-modal-media-max-height;
    background: url('../images/void.png') repeat;
    object-fit: contain;
  }

  .loading-bar {
    position: relative;
  }

  &.image-loader--amorphous .image-loader__preview-canvas {
    display: none;
  }
}

.zoomable-image {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: $media-modal-media-max-width;
    max-height: $media-modal-media-max-height;
    width: auto;
    height: auto;
    object-fit: contain;
  }
}

.navigation-bar {
  padding: 10px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  cursor: default;
  color: $darker-text-color;

  strong {
    color: $secondary-text-color;
  }

  a {
    color: inherit;
  }

  .permalink {
    text-decoration: none;
  }

  .navigation-bar__actions {
    position: relative;

    .icon-button.close {
      position: absolute;
      pointer-events: none;
      transform: scale(0.0, 1.0) translate(-100%, 0);
      opacity: 0;
    }

    .compose__action-bar .icon-button {
      pointer-events: auto;
      transform: scale(1.0, 1.0) translate(0, 0);
      opacity: 1;
    }
  }
}

.navigation-bar__profile {
  flex: 1 1 auto;
  margin-left: 8px;
  line-height: 20px;
  margin-top: -1px;
  overflow: hidden;
}

.navigation-bar__profile-account {
  display: block;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navigation-bar__profile-edit {
  color: inherit;
  text-decoration: none;
}

.dropdown {
  display: inline-block;
}

.dropdown__content {
  display: none;
  position: absolute;
}

.dropdown-menu__separator {
  border-bottom: 1px solid darken($ui-secondary-color, 8%);
  margin: 5px 7px 6px;
  height: 0;
}

.dropdown-menu {
  background: $ui-secondary-color;
  padding: 4px 0;
  border-radius: 4px;
  box-shadow: 2px 4px 15px rgba($base-shadow-color, 0.4);
  z-index: 9999;

  ul {
    list-style: none;
  }

  &.left {
    transform-origin: 100% 50%;
  }

  &.top {
    transform-origin: 50% 100%;
  }

  &.bottom {
    transform-origin: 50% 0;
  }

  &.right {
    transform-origin: 0 50%;
  }
}

.dropdown-menu__arrow {
  position: absolute;
  width: 0;
  height: 0;
  border: 0 solid transparent;

  &.left {
    right: -5px;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: $ui-secondary-color;
  }

  &.top {
    bottom: -5px;
    margin-left: -7px;
    border-width: 5px 7px 0;
    border-top-color: $ui-secondary-color;
  }

  &.bottom {
    top: -5px;
    margin-left: -7px;
    border-width: 0 7px 5px;
    border-bottom-color: $ui-secondary-color;
  }

  &.right {
    left: -5px;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: $ui-secondary-color;
  }
}

.dropdown-menu__item {
  a {
    font-size: 13px;
    line-height: 18px;
    display: block;
    padding: 4px 14px;
    box-sizing: border-box;
    text-decoration: none;
    background: $ui-secondary-color;
    color: $inverted-text-color;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:focus,
    &:hover,
    &:active {
      background: $ui-highlight-color;
      color: $secondary-text-color;
      outline: 0;
    }
  }
}

.dropdown--active .dropdown__content {
  display: block;
  line-height: 18px;
  max-width: 311px;
  right: 0;
  text-align: left;
  z-index: 9999;

  & > ul {
    list-style: none;
    background: $ui-secondary-color;
    padding: 4px 0;
    border-radius: 4px;
    box-shadow: 0 0 15px rgba($base-shadow-color, 0.4);
    min-width: 140px;
    position: relative;
  }

  &.dropdown__right {
    right: 0;
  }

  &.dropdown__left {
    & > ul {
      left: -98px;
    }
  }

  & > ul > li > a {
    font-size: 13px;
    line-height: 18px;
    display: block;
    padding: 4px 14px;
    box-sizing: border-box;
    text-decoration: none;
    background: $ui-secondary-color;
    color: $inverted-text-color;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:focus {
      outline: 0;
    }

    &:hover {
      background: $ui-highlight-color;
      color: $secondary-text-color;
    }
  }
}

.dropdown__icon {
  vertical-align: middle;
}

.columns-area {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: auto;
  position: relative;

  &.unscrollable {
    overflow-x: hidden;
  }
}

@media screen and (min-width: 360px) {
  .columns-area {
    padding: 10px;
  }

  .react-swipeable-view-container .columns-area {
    height: calc(100% - 20px) !important;
  }
}

.react-swipeable-view-container {
  &,
  .columns-area,
  .drawer,
  .column {
    height: 100%;
  }
}

.react-swipeable-view-container > * {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.column {
  width: 350px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  > .scrollable {
    background: $ui-base-color;
  }
}

.ui {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: darken($ui-base-color, 7%);
}

.drawer {
  width: 300px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.drawer__tab {
  display: block;
  flex: 1 1 auto;
  padding: 15px 5px 13px;
  color: $darker-text-color;
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  border-bottom: 2px solid transparent;
}

.column,
.drawer {
  flex: 1 1 100%;
  overflow: hidden;
}

@media screen and (min-width: 360px) {
  .tabs-bar {
    margin: 10px;
    margin-bottom: 0;
  }

  .getting-started__wrapper,
  .getting-started__trends,
  .search {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 630px) {
  .column,
  .drawer {
    width: 100%;
    padding: 0;
  }

  .columns-area {
    flex-direction: column;
  }

  .search__input,
  .autosuggest-textarea__textarea {
    font-size: 16px;
  }
}

@media screen and (min-width: 631px) {
  .columns-area {
    padding: 0;
  }

  .column,
  .drawer {
    flex: 0 0 auto;
    padding: 10px;
    padding-left: 5px;
    padding-right: 5px;

    &:first-child {
      padding-left: 10px;
    }

    &:last-child {
      padding-right: 10px;
    }
  }

  .columns-area > div {
    .column,
    .drawer {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.drawer__pager {
  box-sizing: border-box;
  padding: 0;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  display: flex;
}

.drawer__inner {
  position: absolute;
  top: 0;
  left: 0;
  background: lighten($ui-base-color, 13%);
  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;

  &.darker {
    background: $ui-base-color;
  }
}

.drawer__inner__mastodon {
  background: lighten($ui-base-color, 13%) url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 234.80078 31.757813" width="234.80078" height="31.757812"><path d="M19.599609 0c-1.05 0-2.10039.375-2.90039 1.125L0 16.925781v14.832031h234.80078V17.025391l-16.5-15.900391c-1.6-1.5-4.20078-1.5-5.80078 0l-13.80078 13.099609c-1.6 1.5-4.19883 1.5-5.79883 0L179.09961 1.125c-1.6-1.5-4.19883-1.5-5.79883 0L159.5 14.224609c-1.6 1.5-4.20078 1.5-5.80078 0L139.90039 1.125c-1.6-1.5-4.20078-1.5-5.80078 0l-13.79883 13.099609c-1.6 1.5-4.20078 1.5-5.80078 0L100.69922 1.125c-1.600001-1.5-4.198829-1.5-5.798829 0l-13.59961 13.099609c-1.6 1.5-4.200781 1.5-5.800781 0L61.699219 1.125c-1.6-1.5-4.198828-1.5-5.798828 0L42.099609 14.224609c-1.6 1.5-4.198828 1.5-5.798828 0L22.5 1.125C21.7.375 20.649609 0 19.599609 0z" fill="#{hex-color($ui-base-color)}"/></svg>') no-repeat bottom / 100% auto;
  flex: 1;
  min-height: 47px;

  > img {
    display: block;
    object-fit: contain;
    object-position: bottom left;
    width: 100%;
    height: 100%;
    pointer-events: none;
    user-drag: none;
    user-select: none;
  }
}

.pseudo-drawer {
  background: lighten($ui-base-color, 13%);
  font-size: 13px;
  text-align: left;
}

.drawer__header {
  flex: 0 0 auto;
  font-size: 16px;
  background: lighten($ui-base-color, 8%);
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;

  a {
    transition: background 100ms ease-in;

    &:hover {
      background: lighten($ui-base-color, 3%);
      transition: background 200ms ease-out;
    }
  }
}

.tabs-bar {
  display: flex;
  background: lighten($ui-base-color, 8%);
  flex: 0 0 auto;
  overflow-y: auto;
}

.tabs-bar__link {
  display: block;
  flex: 1 1 auto;
  padding: 15px 10px;
  color: $primary-text-color;
  text-decoration: none;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 2px solid lighten($ui-base-color, 8%);
  transition: all 50ms linear;

  .fa {
    font-weight: 400;
    font-size: 16px;
  }

  &.active {
    border-bottom: 2px solid $highlight-text-color;
    color: $highlight-text-color;
  }

  &:hover,
  &:focus,
  &:active {
    @media screen and (min-width: 631px) {
      background: lighten($ui-base-color, 14%);
    }
  }

  span {
    margin-left: 5px;
    display: none;
  }
}

@media screen and (min-width: 600px) {
  .tabs-bar__link {
    span {
      display: inline;
    }
  }
}

@media screen and (min-width: 631px) {
  .tabs-bar {
    display: none;
  }
}

.scrollable {
  overflow-y: scroll;
  overflow-x: hidden;
  flex: 1 1 auto;
  -webkit-overflow-scrolling: touch;
  will-change: transform; // improves perf in mobile Chrome

  &.optionally-scrollable {
    overflow-y: auto;
  }

  @supports(display: grid) { // hack to fix Chrome <57
    contain: strict;
  }

  &--flex {
    display: flex;
    flex-direction: column;
  }

  &__append {
    flex: 1 1 auto;
    position: relative;
    min-height: 120px;
  }
}

.scrollable.fullscreen {
  @supports(display: grid) { // hack to fix Chrome <57
    contain: none;
  }
}

.column-back-button {
  background: lighten($ui-base-color, 4%);
  color: $highlight-text-color;
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 16px;
  line-height: inherit;
  border: 0;
  text-align: unset;
  padding: 15px;
  margin: 0;
  z-index: 3;
  outline: 0;

  &:hover {
    text-decoration: underline;
  }
}

.column-header__back-button {
  background: lighten($ui-base-color, 4%);
  border: 0;
  font-family: inherit;
  color: $highlight-text-color;
  cursor: pointer;
  white-space: nowrap;
  font-size: 16px;
  padding: 0 5px 0 0;
  z-index: 3;

  &:hover {
    text-decoration: underline;
  }

  &:last-child {
    padding: 0 15px 0 0;
  }
}

.column-back-button__icon {
  display: inline-block;
  margin-right: 5px;
}

.column-back-button--slim {
  position: relative;
}

.column-back-button--slim-button {
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 16px;
  padding: 15px;
  position: absolute;
  right: 0;
  top: -48px;
}

.react-toggle {
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  user-select: none;
  -webkit-tap-highlight-color: rgba($base-overlay-background, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: $ui-base-color;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: darken($ui-base-color, 10%);
}

.react-toggle--checked .react-toggle-track {
  background-color: $ui-highlight-color;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: lighten($ui-highlight-color, 10%);
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid $ui-base-color;
  border-radius: 50%;
  background-color: darken($simple-background-color, 2%);
  box-sizing: border-box;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: $ui-highlight-color;
}

.column-link {
  background: lighten($ui-base-color, 8%);
  color: $primary-text-color;
  display: block;
  font-size: 16px;
  padding: 15px;
  text-decoration: none;

  &:hover {
    background: lighten($ui-base-color, 11%);
  }
}

.column-link__icon {
  display: inline-block;
  margin-right: 5px;
}

.column-link__badge {
  display: inline-block;
  border-radius: 4px;
  font-size: 12px;
  line-height: 19px;
  font-weight: 500;
  background: $ui-base-color;
  padding: 4px 8px;
  margin: -6px 10px;
}

.column-subheading {
  background: $ui-base-color;
  color: $dark-text-color;
  padding: 8px 20px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: default;
}

.getting-started__wrapper,
.getting-started,
.flex-spacer {
  background: $ui-base-color;
}

.getting-started__wrapper {
  flex: 0 0 auto;
}

.flex-spacer {
  flex: 1 1 auto;
}

.getting-started {
  color: $dark-text-color;
  overflow: auto;

  &__footer {
    flex: 0 0 auto;
    padding: 10px;
    padding-top: 20px;

    ul {
      margin-bottom: 10px;
    }

    ul li {
      display: inline;
    }

    p {
      color: $dark-text-color;
      font-size: 13px;
      margin-bottom: 20px;

      a {
        color: $dark-text-color;
        text-decoration: underline;
      }
    }

    a {
      text-decoration: none;
      color: $darker-text-color;

      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
      }
    }
  }

  &__trends {
    background: $ui-base-color;
    flex: 0 1 auto;

    @media screen and (max-height: 810px) {
      .trends__item:nth-child(3) {
        display: none;
      }
    }

    @media screen and (max-height: 720px) {
      .trends__item:nth-child(2) {
        display: none;
      }
    }

    @media screen and (max-height: 670px) {
      display: none;
    }
  }

  &__scrollable {
    max-height: 100%;
    overflow-y: auto;
  }
}

.keyboard-shortcuts {
  padding: 8px 0 0;
  overflow: hidden;

  thead {
    position: absolute;
    left: -9999px;
  }

  td {
    padding: 0 10px 8px;
  }

  kbd {
    display: inline-block;
    padding: 3px 5px;
    background-color: lighten($ui-base-color, 8%);
    border: 1px solid darken($ui-base-color, 4%);
  }
}

.setting-text {
  color: $darker-text-color;
  background: transparent;
  border: none;
  border-bottom: 2px solid $ui-primary-color;
  box-sizing: border-box;
  display: block;
  font-family: inherit;
  margin-bottom: 10px;
  padding: 7px 0;
  width: 100%;

  &:focus,
  &:active {
    color: $primary-text-color;
    border-bottom-color: $highlight-text-color;
  }

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
}

.no-reduce-motion button.icon-button i.fa-retweet {
  background-position: 0 0;
  height: 19px;
  transition: background-position 0.9s steps(10);
  transition-duration: 0s;
  vertical-align: middle;
  width: 22px;

  &::before {
    display: none !important;
  }

}

.no-reduce-motion button.icon-button.active i.fa-retweet {
  transition-duration: 0.9s;
  background-position: 0 100%;
}

.reduce-motion button.icon-button i.fa-retweet {
  color: $action-button-color;
  transition: color 100ms ease-in;
}

.reduce-motion button.icon-button.active i.fa-retweet {
  color: $highlight-text-color;
}

.status-card {
  display: flex;
  font-size: 14px;
  border: 1px solid lighten($ui-base-color, 8%);
  border-radius: 4px;
  color: $dark-text-color;
  margin-top: 14px;
  text-decoration: none;
  overflow: hidden;

  &__actions {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      background: rgba($base-shadow-color, 0.6);
      border-radius: 4px;
      padding: 12px 9px;
      flex: 0 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    button,
    a {
      display: inline;
      color: $primary-text-color;
      background: transparent;
      border: 0;
      padding: 0 5px;
      text-decoration: none;
      opacity: 0.6;
      font-size: 18px;
      line-height: 18px;

      &:hover,
      &:active,
      &:focus {
        opacity: 1;
      }
    }

    a {
      font-size: 19px;
      position: relative;
      bottom: -1px;
    }
  }
}

a.status-card {
  cursor: pointer;

  &:hover {
    background: lighten($ui-base-color, 8%);
  }
}

.status-card-photo {
  cursor: zoom-in;
  display: block;
  text-decoration: none;
  width: 100%;
  height: auto;
  margin: 0;
}

.status-card-video {
  iframe {
    width: 100%;
    height: 100%;
  }
}

.status-card__title {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
  color: $darker-text-color;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
}

.status-card__content {
  flex: 1 1 auto;
  overflow: hidden;
  padding: 14px 14px 14px 8px;
}

.status-card__description {
  color: $darker-text-color;
}

.status-card__host {
  display: block;
  margin-top: 5px;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.status-card__image {
  flex: 0 0 100px;
  background: lighten($ui-base-color, 8%);
  position: relative;

  & > .fa {
    font-size: 21px;
    position: absolute;
    transform-origin: 50% 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.status-card.horizontal {
  display: block;

  .status-card__image {
    width: 100%;
  }

  .status-card__image-image {
    border-radius: 4px 4px 0 0;
  }

  .status-card__title {
    white-space: inherit;
  }
}

.status-card.compact {
  border-color: lighten($ui-base-color, 4%);

  &.interactive {
    border: 0;
  }

  .status-card__content {
    padding: 8px;
    padding-top: 10px;
  }

  .status-card__title {
    white-space: nowrap;
  }

  .status-card__image {
    flex: 0 0 60px;
  }
}

a.status-card.compact:hover {
  background-color: lighten($ui-base-color, 4%);
}

.status-card__image-image {
  border-radius: 4px 0 0 4px;
  display: block;
  margin: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
  background-position: center center;
}

.load-more {
  display: block;
  color: $dark-text-color;
  background-color: transparent;
  border: 0;
  font-size: inherit;
  text-align: center;
  line-height: inherit;
  margin: 0;
  padding: 15px;
  box-sizing: border-box;
  width: 100%;
  clear: both;
  text-decoration: none;

  &:hover {
    background: lighten($ui-base-color, 2%);
  }
}

.load-gap {
  border-bottom: 1px solid lighten($ui-base-color, 8%);
}

.regeneration-indicator {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: $dark-text-color;
  background: $ui-base-color;
  cursor: default;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  padding: 20px;

  & > div {
    width: 100%;
    background: transparent;
    padding-top: 0;
  }

  &__figure {
    background: url('../images/elephant_ui_working.svg') no-repeat center 0;
    width: 100%;
    height: 160px;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.missing-indicator {
    padding-top: 20px + 48px;

    .regeneration-indicator__figure {
      background-image: url('../images/elephant_ui_disappointed.svg');
    }
  }

  &__label {
    margin-top: 200px;

    strong {
      display: block;
      margin-bottom: 10px;
      color: $dark-text-color;
    }

    span {
      font-size: 15px;
      font-weight: 400;
    }
  }
}

.column-header__wrapper {
  position: relative;
  flex: 0 0 auto;

  &.active {
    &::before {
      display: block;
      content: "";
      position: absolute;
      top: 35px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 60%;
      pointer-events: none;
      height: 28px;
      z-index: 1;
      background: radial-gradient(ellipse, rgba($ui-highlight-color, 0.23) 0%, rgba($ui-highlight-color, 0) 60%);
    }
  }
}

.column-header {
  display: flex;
  font-size: 16px;
  background: lighten($ui-base-color, 4%);
  flex: 0 0 auto;
  cursor: pointer;
  position: relative;
  z-index: 2;
  outline: 0;
  overflow: hidden;

  & > button {
    margin: 0;
    border: none;
    padding: 15px 0 15px 15px;
    color: inherit;
    background: transparent;
    font: inherit;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex: 1;
  }

  & > .column-header__back-button {
    color: $highlight-text-color;
  }

  &.active {
    box-shadow: 0 1px 0 rgba($highlight-text-color, 0.3);

    .column-header__icon {
      color: $highlight-text-color;
      text-shadow: 0 0 10px rgba($highlight-text-color, 0.4);
    }
  }

  &:focus,
  &:active {
    outline: 0;
  }
}

.column-header__buttons {
  height: 48px;
  display: flex;
}

.column-header__links .text-btn {
  margin-right: 10px;
}

.column-header__button {
  background: lighten($ui-base-color, 4%);
  border: 0;
  color: $darker-text-color;
  cursor: pointer;
  font-size: 16px;
  padding: 0 15px;

  &:hover {
    color: lighten($darker-text-color, 7%);
  }

  &.active {
    color: $primary-text-color;
    background: lighten($ui-base-color, 8%);

    &:hover {
      color: $primary-text-color;
      background: lighten($ui-base-color, 8%);
    }
  }
}

.column-header__collapsible {
  max-height: 70vh;
  overflow: hidden;
  overflow-y: auto;
  color: $darker-text-color;
  transition: max-height 150ms ease-in-out, opacity 300ms linear;
  opacity: 1;

  &.collapsed {
    max-height: 0;
    opacity: 0.5;
  }

  &.animating {
    overflow-y: hidden;
  }

  hr {
    height: 0;
    background: transparent;
    border: 0;
    border-top: 1px solid lighten($ui-base-color, 12%);
    margin: 10px 0;
  }
}

.column-header__collapsible-inner {
  background: lighten($ui-base-color, 8%);
  padding: 15px;
}

.column-header__setting-btn {
  &:hover {
    color: $darker-text-color;
    text-decoration: underline;
  }
}

.column-header__setting-arrows {
  float: right;

  .column-header__setting-btn {
    padding: 0 10px;

    &:last-child {
      padding-right: 0;
    }
  }
}

.text-btn {
  display: inline-block;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  border: 0;
  background: transparent;
  cursor: pointer;
}

.column-header__icon {
  display: inline-block;
  margin-right: 5px;
}

.loading-indicator {
  color: $dark-text-color;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  overflow: visible;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  span {
    display: block;
    float: left;
    margin-left: 50%;
    transform: translateX(-50%);
    margin: 82px 0 0 50%;
    white-space: nowrap;
  }
}

.loading-indicator__figure {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 42px;
  height: 42px;
  box-sizing: border-box;
  background-color: transparent;
  border: 0 solid lighten($ui-base-color, 26%);
  border-width: 6px;
  border-radius: 50%;
}

.no-reduce-motion .loading-indicator span {
  animation: loader-label 1.15s infinite cubic-bezier(0.215, 0.610, 0.355, 1.000);
}

.no-reduce-motion .loading-indicator__figure {
  animation: loader-figure 1.15s infinite cubic-bezier(0.215, 0.610, 0.355, 1.000);
}

@keyframes loader-figure {
  0% {
    width: 0;
    height: 0;
    background-color: lighten($ui-base-color, 26%);
  }

  29% {
    background-color: lighten($ui-base-color, 26%);
  }

  30% {
    width: 42px;
    height: 42px;
    background-color: transparent;
    border-width: 21px;
    opacity: 1;
  }

  100% {
    width: 42px;
    height: 42px;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@keyframes loader-label {
  0% { opacity: 0.25; }
  30% { opacity: 1; }
  100% { opacity: 0.25; }
}

.video-error-cover {
  align-items: center;
  background: $base-overlay-background;
  color: $primary-text-color;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-top: 8px;
  position: relative;
  text-align: center;
  z-index: 100;
}

.media-spoiler {
  background: $base-overlay-background;
  color: $darker-text-color;
  border: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  appearance: none;

  &:hover,
  &:active,
  &:focus {
    padding: 0;
    color: lighten($darker-text-color, 8%);
  }
}

.media-spoiler__warning {
  display: block;
  font-size: 14px;
}

.media-spoiler__trigger {
  display: block;
  font-size: 11px;
  font-weight: 700;
}

.spoiler-button {
  display: none;
  left: 4px;
  position: absolute;
  text-shadow: 0 1px 1px $base-shadow-color, 1px 0 1px $base-shadow-color;
  top: 4px;
  z-index: 100;

  &.spoiler-button--visible {
    display: block;
  }
}

.modal-container--preloader {
  background: lighten($ui-base-color, 8%);
}

.account--panel {
  background: lighten($ui-base-color, 4%);
  border-top: 1px solid lighten($ui-base-color, 8%);
  border-bottom: 1px solid lighten($ui-base-color, 8%);
  display: flex;
  flex-direction: row;
  padding: 10px 0;
}

.account--panel__button,
.detailed-status__button {
  flex: 1 1 auto;
  text-align: center;
}

.column-settings__outer {
  background: lighten($ui-base-color, 8%);
  padding: 15px;
}

.column-settings__section {
  color: $darker-text-color;
  cursor: default;
  display: block;
  font-weight: 500;
  margin-bottom: 10px;
}

.column-settings__hashtags {
  .column-settings__row {
    margin-bottom: 15px;
  }

  .column-select {
    &__control {
      @include search-input();
    }

    &__placeholder {
      color: $dark-text-color;
      padding-left: 2px;
      font-size: 12px;
    }

    &__value-container {
      padding-left: 6px;
    }

    &__multi-value {
      background: lighten($ui-base-color, 8%);

      &__remove {
        cursor: pointer;

        &:hover,
        &:active,
        &:focus {
          background: lighten($ui-base-color, 12%);
          color: lighten($darker-text-color, 4%);
        }
      }
    }

    &__multi-value__label,
    &__input {
      color: $darker-text-color;
    }

    &__clear-indicator,
    &__dropdown-indicator {
      cursor: pointer;
      transition: none;
      color: $dark-text-color;

      &:hover,
      &:active,
      &:focus {
        color: lighten($dark-text-color, 4%);
      }
    }

    &__indicator-separator {
      background-color: lighten($ui-base-color, 8%);
    }

    &__menu {
      @include search-popout();
      padding: 0;
      background: $ui-secondary-color;
    }

    &__menu-list {
      padding: 6px;
    }

    &__option {
      color: $inverted-text-color;
      border-radius: 4px;
      font-size: 14px;

      &--is-focused,
      &--is-selected {
        background: darken($ui-secondary-color, 10%);
      }
    }
  }
}

.column-settings__row {
  .text-btn {
    margin-bottom: 15px;
  }
}

.relationship-tag {
  color: $primary-text-color;
  margin-bottom: 4px;
  display: block;
  vertical-align: top;
  background-color: $base-overlay-background;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  padding: 4px;
  border-radius: 4px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}

.setting-toggle {
  display: block;
  line-height: 24px;
}

.setting-toggle__label {
  color: $darker-text-color;
  display: inline-block;
  margin-bottom: 14px;
  margin-left: 8px;
  vertical-align: middle;
}

.empty-column-indicator,
.error-column {
  color: $dark-text-color;
  background: $ui-base-color;
  text-align: center;
  padding: 20px;
  font-size: 15px;
  font-weight: 400;
  cursor: default;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;

  @supports(display: grid) { // hack to fix Chrome <57
    contain: strict;
  }

  a {
    color: $highlight-text-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.error-column {
  flex-direction: column;
}

@keyframes heartbeat {
  from {
    transform: scale(1);
    animation-timing-function: ease-out;
  }

  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }

  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }

  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }

  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}

.no-reduce-motion .pulse-loading {
  transform-origin: center center;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

@keyframes shake-bottom {
  0%,
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 100%;
  }

  10% {
    transform: rotate(2deg);
  }

  20%,
  40%,
  60% {
    transform: rotate(-4deg);
  }

  30%,
  50%,
  70% {
    transform: rotate(4deg);
  }

  80% {
    transform: rotate(-2deg);
  }

  90% {
    transform: rotate(2deg);
  }
}

.no-reduce-motion .shake-bottom {
  transform-origin: 50% 100%;
  animation: shake-bottom 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) 2s 2 both;
}

.emoji-picker-dropdown__menu {
  background: $simple-background-color;
  position: absolute;
  box-shadow: 4px 4px 6px rgba($base-shadow-color, 0.4);
  border-radius: 4px;
  margin-top: 5px;

  .emoji-mart-scroll {
    transition: opacity 200ms ease;
  }

  &.selecting .emoji-mart-scroll {
    opacity: 0.5;
  }
}

.emoji-picker-dropdown__modifiers {
  position: absolute;
  top: 60px;
  right: 11px;
  cursor: pointer;
}

.emoji-picker-dropdown__modifiers__menu {
  position: absolute;
  z-index: 4;
  top: -4px;
  left: -8px;
  background: $simple-background-color;
  border-radius: 4px;
  box-shadow: 1px 2px 6px rgba($base-shadow-color, 0.2);
  overflow: hidden;

  button {
    display: block;
    cursor: pointer;
    border: 0;
    padding: 4px 8px;
    background: transparent;

    &:hover,
    &:focus,
    &:active {
      background: rgba($ui-secondary-color, 0.4);
    }
  }

  .emoji-mart-emoji {
    height: 22px;
  }
}

.emoji-mart-emoji {
  span {
    background-repeat: no-repeat;
  }
}

.upload-area {
  align-items: center;
  background: rgba($base-overlay-background, 0.8);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 2000;

  * {
    pointer-events: none;
  }
}

.upload-area__drop {
  width: 320px;
  height: 160px;
  display: flex;
  box-sizing: border-box;
  position: relative;
  padding: 8px;
}

.upload-area__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: 4px;
  background: $ui-base-color;
  box-shadow: 0 0 5px rgba($base-shadow-color, 0.2);
}

.upload-area__content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $secondary-text-color;
  font-size: 18px;
  font-weight: 500;
  border: 2px dashed $ui-base-lighter-color;
  border-radius: 4px;
}

.upload-progress {
  padding: 10px;
  color: $lighter-text-color;
  overflow: hidden;
  display: flex;

  .fa {
    font-size: 34px;
    margin-right: 10px;
  }

  span {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    display: block;
  }
}

.upload-progess__message {
  flex: 1 1 auto;
}

.upload-progress__backdrop {
  width: 100%;
  height: 6px;
  border-radius: 6px;
  background: $ui-base-lighter-color;
  position: relative;
  margin-top: 5px;
}

.upload-progress__tracker {
  position: absolute;
  left: 0;
  top: 0;
  height: 6px;
  background: $ui-highlight-color;
  border-radius: 6px;
}

.emoji-button {
  display: block;
  font-size: 24px;
  line-height: 24px;
  margin-left: 2px;
  width: 24px;
  outline: 0;
  cursor: pointer;

  &:active,
  &:focus {
    outline: 0 !important;
  }

  img {
    filter: grayscale(100%);
    opacity: 0.8;
    display: block;
    margin: 0;
    width: 22px;
    height: 22px;
    margin-top: 2px;
  }

  &:hover,
  &:active,
  &:focus {
    img {
      opacity: 1;
      filter: none;
    }
  }
}

.dropdown--active .emoji-button img {
  opacity: 1;
  filter: none;
}

.privacy-dropdown__dropdown {
  position: absolute;
  background: $simple-background-color;
  box-shadow: 2px 4px 15px rgba($base-shadow-color, 0.4);
  border-radius: 4px;
  margin-left: 40px;
  overflow: hidden;

  &.top {
    transform-origin: 50% 100%;
  }

  &.bottom {
    transform-origin: 50% 0;
  }
}

.privacy-dropdown__option {
  color: $inverted-text-color;
  padding: 10px;
  cursor: pointer;
  display: flex;

  &:hover,
  &.active {
    background: $ui-highlight-color;
    color: $primary-text-color;
    outline: 0;

    .privacy-dropdown__option__content {
      color: $primary-text-color;

      strong {
        color: $primary-text-color;
      }
    }
  }

  &.active:hover {
    background: lighten($ui-highlight-color, 4%);
  }
}

.privacy-dropdown__option__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.privacy-dropdown__option__content {
  flex: 1 1 auto;
  color: $lighter-text-color;

  strong {
    font-weight: 500;
    display: block;
    color: $inverted-text-color;

    @each $lang in $cjk-langs {
      &:lang(#{$lang}) {
        font-weight: 700;
      }
    }
  }
}

.privacy-dropdown.active {
  .privacy-dropdown__value {
    background: $simple-background-color;
    border-radius: 4px 4px 0 0;
    box-shadow: 0 -4px 4px rgba($base-shadow-color, 0.1);

    .icon-button {
      transition: none;
    }

    &.active {
      background: $ui-highlight-color;

      .icon-button {
        color: $primary-text-color;
      }
    }
  }

  &.top .privacy-dropdown__value {
    border-radius: 0 0 4px 4px;
  }

  .privacy-dropdown__dropdown {
    display: block;
    box-shadow: 2px 4px 6px rgba($base-shadow-color, 0.1);
  }
}

.search {
  position: relative;
}

.search__input {
  display: block;
  padding: 10px;
  padding-right: 30px;
  @include search-input();
}

.search__icon {
  &::-moz-focus-inner {
    border: 0;
  }

  &::-moz-focus-inner,
  &:focus {
    outline: 0 !important;
  }

  .fa {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    display: inline-block;
    opacity: 0;
    transition: all 100ms linear;
    font-size: 18px;
    width: 18px;
    height: 18px;
    color: $secondary-text-color;
    cursor: default;
    pointer-events: none;

    &.active {
      pointer-events: auto;
      opacity: 0.3;
    }
  }

  .fa-search {
    transform: rotate(90deg);

    &.active {
      pointer-events: none;
      transform: rotate(0deg);
    }
  }

  .fa-times-circle {
    top: 11px;
    transform: rotate(0deg);
    color: $action-button-color;
    cursor: pointer;

    &.active {
      transform: rotate(90deg);
    }

    &:hover {
      color: lighten($action-button-color, 7%);
    }
  }
}

.search-results__header {
  color: $dark-text-color;
  background: lighten($ui-base-color, 2%);
  padding: 15px;
  font-weight: 500;
  font-size: 16px;
  cursor: default;

  .fa {
    display: inline-block;
    margin-right: 5px;
  }
}

.search-results__section {
  margin-bottom: 5px;

  h5 {
    background: darken($ui-base-color, 4%);
    border-bottom: 1px solid lighten($ui-base-color, 8%);
    cursor: default;
    display: flex;
    padding: 15px;
    font-weight: 500;
    font-size: 16px;
    color: $dark-text-color;

    .fa {
      display: inline-block;
      margin-right: 5px;
    }
  }

  .account:last-child,
  & > div:last-child .status {
    border-bottom: 0;
  }
}

.search-results__hashtag {
  display: block;
  padding: 10px;
  color: $secondary-text-color;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: lighten($secondary-text-color, 4%);
    text-decoration: underline;
  }
}

.modal-root {
  position: relative;
  transition: opacity 0.3s linear;
  will-change: opacity;
  z-index: 9999;
}

.modal-root__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($base-overlay-background, 0.7);
}

.modal-root__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: space-around;
  z-index: 9999;
  pointer-events: none;
  user-select: none;
}

.modal-root__modal {
  pointer-events: auto;
  display: flex;
  z-index: 9999;
}

.video-modal {
  max-width: 100vw;
  max-height: 100vh;
  position: relative;
}

.media-modal {
  width: 100%;
  height: 100%;
  position: relative;

  .extended-video-player {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    video {
      max-width: $media-modal-media-max-width;
      max-height: $media-modal-media-max-height;
    }
  }
}

.media-modal__closer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.media-modal__navigation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  transition: opacity 0.3s linear;
  will-change: opacity;

  * {
    pointer-events: auto;
  }

  &.media-modal__navigation--hidden {
    opacity: 0;

    * {
      pointer-events: none;
    }
  }
}

.media-modal__nav {
  background: rgba($base-overlay-background, 0.5);
  box-sizing: border-box;
  border: 0;
  color: $primary-text-color;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 24px;
  height: 20vmax;
  margin: auto 0;
  padding: 30px 15px;
  position: absolute;
  top: 0;
  bottom: 0;
}

.media-modal__nav--left {
  left: 0;
}

.media-modal__nav--right {
  right: 0;
}

.media-modal__pagination {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 20px;
  pointer-events: none;
}

.media-modal__page-dot {
  display: inline-block;
}

.media-modal__button {
  background-color: $primary-text-color;
  height: 12px;
  width: 12px;
  border-radius: 6px;
  margin: 10px;
  padding: 0;
  border: 0;
  font-size: 0;
}

.media-modal__button--active {
  background-color: $highlight-text-color;
}

.media-modal__close {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 100;
}

.onboarding-modal,
.error-modal,
.embed-modal {
  background: $ui-secondary-color;
  color: $inverted-text-color;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.error-modal__body {
  height: 80vh;
  width: 80vw;
  max-width: 520px;
  max-height: 420px;
  position: relative;

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 25px;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    opacity: 0;
    user-select: text;
  }
}

.error-modal__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.onboarding-modal__paginator,
.error-modal__footer {
  flex: 0 0 auto;
  background: darken($ui-secondary-color, 8%);
  display: flex;
  padding: 25px;

  & > div {
    min-width: 33px;
  }

  .onboarding-modal__nav,
  .error-modal__nav {
    color: $lighter-text-color;
    border: 0;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 25px;
    line-height: inherit;
    height: auto;
    margin: -10px;
    border-radius: 4px;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active {
      color: darken($lighter-text-color, 4%);
      background-color: darken($ui-secondary-color, 16%);
    }

    &.onboarding-modal__done,
    &.onboarding-modal__next {
      color: $inverted-text-color;

      &:hover,
      &:focus,
      &:active {
        color: lighten($inverted-text-color, 4%);
      }
    }
  }
}

.error-modal__footer {
  justify-content: center;
}

.display-case {
  text-align: center;
  font-size: 15px;
  margin-bottom: 15px;

  &__label {
    font-weight: 500;
    color: $inverted-text-color;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: 12px;
  }

  &__case {
    background: $ui-base-color;
    color: $secondary-text-color;
    font-weight: 500;
    padding: 10px;
    border-radius: 4px;
  }
}

.onboard-sliders {
  display: inline-block;
  max-width: 30px;
  max-height: auto;
  margin-left: 10px;
}

.boost-modal,
.confirmation-modal,
.report-modal,
.actions-modal,
.mute-modal {
  background: lighten($ui-secondary-color, 8%);
  color: $inverted-text-color;
  border-radius: 8px;
  overflow: hidden;
  max-width: 90vw;
  width: 480px;
  position: relative;
  flex-direction: column;

  .status__display-name {
    display: block;
    max-width: 100%;
    padding-right: 25px;
  }

  .status__avatar {
    height: 28px;
    left: 10px;
    position: absolute;
    top: 10px;
    width: 48px;
  }

  .status__content__spoiler-link {
    color: lighten($secondary-text-color, 8%);
  }
}

.actions-modal {
  .status {
    background: $white;
    border-bottom-color: $ui-secondary-color;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .dropdown-menu__separator {
    border-bottom-color: $ui-secondary-color;
  }
}

.boost-modal__container {
  overflow-x: scroll;
  padding: 10px;

  .status {
    user-select: text;
    border-bottom: 0;
  }
}

.boost-modal__action-bar,
.confirmation-modal__action-bar,
.mute-modal__action-bar {
  display: flex;
  justify-content: space-between;
  background: $ui-secondary-color;
  padding: 10px;
  line-height: 36px;

  & > div {
    flex: 1 1 auto;
    text-align: right;
    color: $lighter-text-color;
    padding-right: 10px;
  }

  .button {
    flex: 0 0 auto;
  }
}

.boost-modal__status-header {
  font-size: 15px;
}

.boost-modal__status-time {
  float: right;
  font-size: 14px;
}

.confirmation-modal {
  max-width: 85vw;

  @media screen and (min-width: 480px) {
    max-width: 380px;
  }
}

.mute-modal {
  line-height: 24px;
}

.mute-modal .react-toggle {
  vertical-align: middle;
}

.report-modal {
  width: 90vw;
  max-width: 700px;
}

.report-modal__container {
  display: flex;
  border-top: 1px solid $ui-secondary-color;

  @media screen and (max-width: 480px) {
    flex-wrap: wrap;
    overflow-y: auto;
  }
}

.report-modal__statuses,
.report-modal__comment {
  box-sizing: border-box;
  width: 50%;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.report-modal__statuses {
  flex: 1 1 auto;
  min-height: 20vh;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;

  .status__content a {
    color: $highlight-text-color;
  }

  .status__content,
  .status__content p {
    color: $inverted-text-color;
  }

  @media screen and (max-width: 480px) {
    max-height: 10vh;
  }
}

.report-modal__comment {
  padding: 20px;
  border-right: 1px solid $ui-secondary-color;
  max-width: 320px;

  p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .setting-text {
    display: block;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    color: $inverted-text-color;
    background: $white;
    padding: 10px;
    font-family: inherit;
    font-size: 14px;
    resize: vertical;
    border: 0;
    outline: 0;
    border-radius: 4px;
    border: 1px solid $ui-secondary-color;
    margin-bottom: 20px;

    &:focus {
      border: 1px solid darken($ui-secondary-color, 8%);
    }
  }

  .setting-toggle {
    margin-top: 20px;
    margin-bottom: 24px;

    &__label {
      color: $inverted-text-color;
      font-size: 14px;
    }
  }

  @media screen and (max-width: 480px) {
    padding: 10px;
    max-width: 100%;
    order: 2;

    .setting-toggle {
      margin-bottom: 4px;
    }
  }
}

.actions-modal {
  .status {
    overflow-y: auto;
    max-height: 300px;
  }

  max-height: 80vh;
  max-width: 80vw;

  .actions-modal__item-label {
    font-weight: 500;
  }

  ul {
    overflow-y: auto;
    flex-shrink: 0;

    li:empty {
      margin: 0;
    }

    li:not(:empty) {
      a {
        color: $inverted-text-color;
        display: flex;
        padding: 12px 16px;
        font-size: 15px;
        align-items: center;
        text-decoration: none;

        &,
        button {
          transition: none;
        }

        &.active,
        &:hover,
        &:active,
        &:focus {
          &,
          button {
            background: $ui-highlight-color;
            color: $primary-text-color;
          }
        }

        button:first-child {
          margin-right: 10px;
        }
      }
    }
  }
}

.confirmation-modal__action-bar,
.mute-modal__action-bar {
  .confirmation-modal__secondary-button,
  .confirmation-modal__cancel-button,
  .mute-modal__cancel-button {
    background-color: transparent;
    color: $lighter-text-color;
    font-size: 14px;
    font-weight: 500;

    &:hover,
    &:focus,
    &:active {
      color: darken($lighter-text-color, 4%);
    }
  }
}

.confirmation-modal__container,
.mute-modal__container,
.report-modal__target {
  padding: 30px;
  font-size: 16px;
  text-align: center;

  strong {
    font-weight: 500;

    @each $lang in $cjk-langs {
      &:lang(#{$lang}) {
        font-weight: 700;
      }
    }
  }
}

.report-modal__target {
  padding: 20px;

  .media-modal__close {
    top: 19px;
    right: 15px;
  }
}

.loading-bar {
  background-color: $highlight-text-color;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
}

.media-gallery__gifv__label {
  display: block;
  position: absolute;
  color: $primary-text-color;
  background: rgba($base-overlay-background, 0.5);
  bottom: 6px;
  left: 6px;
  padding: 2px 6px;
  border-radius: 2px;
  font-size: 11px;
  font-weight: 600;
  z-index: 1;
  pointer-events: none;
  opacity: 0.9;
  transition: opacity 0.1s ease;
}

.media-gallery__gifv {
  &.autoplay {
    .media-gallery__gifv__label {
      display: none;
    }
  }

  &:hover {
    .media-gallery__gifv__label {
      opacity: 1;
    }
  }
}

.attachment-list {
  display: flex;
  font-size: 14px;
  border: 1px solid lighten($ui-base-color, 8%);
  border-radius: 4px;
  margin-top: 14px;
  overflow: hidden;

  &__icon {
    flex: 0 0 auto;
    color: $dark-text-color;
    padding: 8px 18px;
    cursor: default;
    border-right: 1px solid lighten($ui-base-color, 8%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 26px;

    .fa {
      display: block;
    }
  }

  &__list {
    list-style: none;
    padding: 4px 0;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    li {
      display: block;
      padding: 4px 0;
    }

    a {
      text-decoration: none;
      color: $dark-text-color;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.compact {
    border: 0;
    margin-top: 4px;

    .attachment-list__list {
      padding: 0;
      display: block;
    }

    .fa {
      color: $dark-text-color;
    }
  }
}

/* Media Gallery */
.media-gallery {
  box-sizing: border-box;
  margin-top: 8px;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
  width: 100%;
}

.media-gallery__item {
  border: none;
  box-sizing: border-box;
  display: block;
  float: left;
  position: relative;
  border-radius: 4px;
  overflow: hidden;

  &.standalone {
    .media-gallery__item-gifv-thumbnail {
      transform: none;
      top: 0;
    }
  }
}

.media-gallery__item-thumbnail {
  cursor: zoom-in;
  display: block;
  text-decoration: none;
  color: $secondary-text-color;
  line-height: 0;

  &,
  img {
    height: 100%;
    width: 100%;
  }

  img {
    object-fit: cover;
  }
}

.media-gallery__gifv {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.media-gallery__item-gifv-thumbnail {
  cursor: zoom-in;
  height: 100%;
  object-fit: cover;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 1;
}

.media-gallery__item-thumbnail-label {
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  position: absolute;
}
/* End Media Gallery */

/* Status Video Player */
.status__video-player {
  background: $base-overlay-background;
  box-sizing: border-box;
  cursor: default; /* May not be needed */
  margin-top: 8px;
  overflow: hidden;
  position: relative;
}

.status__video-player-video {
  height: 100%;
  object-fit: cover;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 1;
}

.status__video-player-expand,
.status__video-player-mute {
  color: $primary-text-color;
  opacity: 0.8;
  position: absolute;
  right: 4px;
  text-shadow: 0 1px 1px $base-shadow-color, 1px 0 1px $base-shadow-color;
}

.status__video-player-spoiler {
  display: none;
  color: $primary-text-color;
  left: 4px;
  position: absolute;
  text-shadow: 0 1px 1px $base-shadow-color, 1px 0 1px $base-shadow-color;
  top: 4px;
  z-index: 100;

  &.status__video-player-spoiler--visible {
    display: block;
  }
}

.status__video-player-expand {
  bottom: 4px;
  z-index: 100;
}

.status__video-player-mute {
  top: 4px;
  z-index: 5;
}

.detailed,
.fullscreen {
  .video-player__volume__current,
  .video-player__volume::before {
    bottom: 27px;
  }

  .video-player__volume__handle {
    bottom: 23px;
  }

}

.video-player {
  overflow: hidden;
  position: relative;
  background: $base-shadow-color;
  max-width: 100%;
  border-radius: 4px;

  &:focus {
    outline: 0;
  }

  video {
    max-width: 100vw;
    max-height: 80vh;
    z-index: 1;
  }

  &.fullscreen {
    width: 100% !important;
    height: 100% !important;
    margin: 0;

    video {
      max-width: 100% !important;
      max-height: 100% !important;
      width: 100% !important;
      height: 100% !important;
    }
  }

  &.inline {
    video {
      object-fit: contain;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__controls {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    background: linear-gradient(0deg, rgba($base-shadow-color, 0.85) 0, rgba($base-shadow-color, 0.45) 60%, transparent);
    padding: 0 15px;
    opacity: 0;
    transition: opacity .1s ease;

    &.active {
      opacity: 1;
    }
  }

  &.inactive {
    video,
    .video-player__controls {
      visibility: hidden;
    }
  }

  &__spoiler {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    border: 0;
    background: $base-overlay-background;
    color: $darker-text-color;
    transition: none;
    pointer-events: none;

    &.active {
      display: block;
      pointer-events: auto;

      &:hover,
      &:active,
      &:focus {
        color: lighten($darker-text-color, 7%);
      }
    }

    &__title {
      display: block;
      font-size: 14px;
    }

    &__subtitle {
      display: block;
      font-size: 11px;
      font-weight: 500;
    }
  }

  &__buttons-bar {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  &__buttons {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.left {
      button {
        padding-left: 0;
      }
    }

    &.right {
      button {
        padding-right: 0;
      }
    }

    button {
      background: transparent;
      padding: 2px 10px;
      font-size: 16px;
      border: 0;
      color: rgba($white, 0.75);

      &:active,
      &:hover,
      &:focus {
        color: $white;
      }
    }
  }

  &__time-sep,
  &__time-total,
  &__time-current {
    font-size: 14px;
    font-weight: 500;
  }

  &__time-current {
    color: $white;
    margin-left: 60px;
  }

  &__time-sep {
    display: inline-block;
    margin: 0 6px;
  }

  &__time-sep,
  &__time-total {
    color: $white;
  }

  &__volume {
    cursor: pointer;
    height: 24px;
    display: inline;

    &::before {
      content: "";
      width: 50px;
      background: rgba($white, 0.35);
      border-radius: 4px;
      display: block;
      position: absolute;
      height: 4px;
      left: 70px;
      bottom: 20px;
    }

    &__current {
      display: block;
      position: absolute;
      height: 4px;
      border-radius: 4px;
      left: 70px;
      bottom: 20px;
      background: lighten($ui-highlight-color, 8%);
    }

    &__handle {
      position: absolute;
      z-index: 3;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      bottom: 16px;
      left: 70px;
      transition: opacity .1s ease;
      background: lighten($ui-highlight-color, 8%);
      box-shadow: 1px 2px 6px rgba($base-shadow-color, 0.2);
      pointer-events: none;
    }
  }

  &__seek {
    cursor: pointer;
    height: 24px;
    position: relative;

    &::before {
      content: "";
      width: 100%;
      background: rgba($white, 0.35);
      border-radius: 4px;
      display: block;
      position: absolute;
      height: 4px;
      top: 10px;
    }

    &__progress,
    &__buffer {
      display: block;
      position: absolute;
      height: 4px;
      border-radius: 4px;
      top: 10px;
      background: lighten($ui-highlight-color, 8%);
    }

    &__buffer {
      background: rgba($white, 0.2);
    }

    &__handle {
      position: absolute;
      z-index: 3;
      opacity: 0;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      top: 6px;
      margin-left: -6px;
      transition: opacity .1s ease;
      background: lighten($ui-highlight-color, 8%);
      box-shadow: 1px 2px 6px rgba($base-shadow-color, 0.2);
      pointer-events: none;

      &.active {
        opacity: 1;
      }
    }

    &:hover {
      .video-player__seek__handle {
        opacity: 1;
      }
    }
  }

  &.detailed,
  &.fullscreen {
    .video-player__buttons {
      button {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}

.media-spoiler-video {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  margin-top: 8px;
  position: relative;
  border: 0;
  display: block;
}

.media-spoiler-video-play-icon {
  border-radius: 100px;
  color: rgba($primary-text-color, 0.8);
  font-size: 36px;
  left: 50%;
  padding: 5px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
/* End Video Player */

.account-gallery__container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2px;
}

.account-gallery__item {
  flex-grow: 1;
  width: 50%;
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  a {
    display: block;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    margin: 2px;
    top: 0;
    left: 0;
    background-color: $base-overlay-background;
    background-size: cover;
    background-position: center;
    position: absolute;
    color: $darker-text-color;
    text-decoration: none;
    border-radius: 4px;

    &:hover,
    &:active,
    &:focus {
      outline: 0;
      color: $secondary-text-color;

      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: rgba($base-overlay-background, 0.3);
        border-radius: 4px;
      }
    }
  }

  &__icons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
  }
}

.notification__filter-bar,
.account__section-headline {
  background: darken($ui-base-color, 4%);
  border-bottom: 1px solid lighten($ui-base-color, 8%);
  cursor: default;
  display: flex;
  flex-shrink: 0;

  button {
    background: darken($ui-base-color, 4%);
    border: 0;
    margin: 0;
  }

  button,
  a {
    display: block;
    flex: 1 1 auto;
    color: $darker-text-color;
    padding: 15px 0;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    position: relative;

    &.active {
      color: $secondary-text-color;

      &::before,
      &::after {
        display: block;
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 0;
        transform: translateX(-50%);
        border-style: solid;
        border-width: 0 10px 10px;
        border-color: transparent transparent lighten($ui-base-color, 8%);
      }

      &::after {
        bottom: -1px;
        border-color: transparent transparent $ui-base-color;
      }
    }
  }
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
}

.search-popout {
  @include search-popout();
}

noscript {
  text-align: center;

  img {
    width: 200px;
    opacity: 0.5;
    animation: flicker 4s infinite;
  }

  div {
    font-size: 14px;
    margin: 30px auto;
    color: $secondary-text-color;
    max-width: 400px;

    a {
      color: $highlight-text-color;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

@keyframes flicker {
  0% { opacity: 1; }
  30% { opacity: 0.75; }
  100% { opacity: 1; }
}

@media screen and (max-width: 630px) and (max-height: 400px) {
  $duration: 400ms;
  $delay: 100ms;

  .tabs-bar,
  .search {
    will-change: margin-top;
    transition: margin-top $duration $delay;
  }

  .navigation-bar {
    will-change: padding-bottom;
    transition: padding-bottom $duration $delay;
  }

  .navigation-bar {
    & > a:first-child {
      will-change: margin-top, margin-left, margin-right, width;
      transition: margin-top $duration $delay, margin-left $duration ($duration + $delay), margin-right $duration ($duration + $delay);
    }

    & > .navigation-bar__profile-edit {
      will-change: margin-top;
      transition: margin-top $duration $delay;
    }

    .navigation-bar__actions {
      & > .icon-button.close {
        will-change: opacity transform;
        transition: opacity $duration * 0.5 $delay,
                    transform $duration $delay;
      }

      & > .compose__action-bar .icon-button {
        will-change: opacity transform;
        transition: opacity $duration * 0.5 $delay + $duration * 0.5,
                    transform $duration $delay;
      }
    }
  }

  .is-composing {
    .tabs-bar,
    .search {
      margin-top: -50px;
    }

    .navigation-bar {
      padding-bottom: 0;

      & > a:first-child {
        margin: -100px 10px 0 -50px;
      }

      .navigation-bar__profile {
        padding-top: 2px;
      }

      .navigation-bar__profile-edit {
        position: absolute;
        margin-top: -60px;
      }

      .navigation-bar__actions {
        .icon-button.close {
          pointer-events: auto;
          opacity: 1;
          transform: scale(1.0, 1.0) translate(0, 0);
          bottom: 5px;
        }

        .compose__action-bar .icon-button {
          pointer-events: none;
          opacity: 0;
          transform: scale(0.0, 1.0) translate(100%, 0);
        }
      }
    }
  }
}

.embed-modal {
  max-width: 80vw;
  max-height: 80vh;

  h4 {
    padding: 30px;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
  }

  .embed-modal__container {
    padding: 10px;

    .hint {
      margin-bottom: 15px;
    }

    .embed-modal__html {
      outline: 0;
      box-sizing: border-box;
      display: block;
      width: 100%;
      border: none;
      padding: 10px;
      font-family: $font-monospace, monospace;
      background: $ui-base-color;
      color: $primary-text-color;
      font-size: 14px;
      margin: 0;
      margin-bottom: 15px;

      &::-moz-focus-inner {
        border: 0;
      }

      &::-moz-focus-inner,
      &:focus,
      &:active {
        outline: 0 !important;
      }

      &:focus {
        background: lighten($ui-base-color, 4%);
      }

      @media screen and (max-width: 600px) {
        font-size: 16px;
      }
    }

    .embed-modal__iframe {
      width: 400px;
      max-width: 100%;
      overflow: hidden;
      border: 0;
    }
  }
}

.account__moved-note {
  padding: 14px 10px;
  padding-bottom: 16px;
  background: lighten($ui-base-color, 4%);
  border-top: 1px solid lighten($ui-base-color, 8%);
  border-bottom: 1px solid lighten($ui-base-color, 8%);

  &__message {
    position: relative;
    margin-left: 58px;
    color: $dark-text-color;
    padding: 8px 0;
    padding-top: 0;
    padding-bottom: 4px;
    font-size: 14px;

    > span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__icon-wrapper {
    left: -26px;
    position: absolute;
  }

  .detailed-status__display-avatar {
    position: relative;
  }

  .detailed-status__display-name {
    margin-bottom: 0;
  }
}

.column-inline-form {
  padding: 7px 15px;
  padding-right: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: lighten($ui-base-color, 4%);

  label {
    flex: 1 1 auto;

    input {
      width: 100%;
      margin-bottom: 6px;

      &:focus {
        outline: 0;
      }
    }
  }

  .icon-button {
    flex: 0 0 auto;
    margin: 0 5px;
  }
}

.drawer__backdrop {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($base-overlay-background, 0.5);
}

.list-editor {
  background: $ui-base-color;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 2px 4px 15px rgba($base-shadow-color, 0.4);
  width: 380px;
  overflow: hidden;

  @media screen and (max-width: 420px) {
    width: 90%;
  }

  h4 {
    padding: 15px 0;
    background: lighten($ui-base-color, 13%);
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    border-radius: 8px 8px 0 0;
  }

  .drawer__pager {
    height: 50vh;
  }

  .drawer__inner {
    border-radius: 0 0 8px 8px;

    &.backdrop {
      width: calc(100% - 60px);
      box-shadow: 2px 4px 15px rgba($base-shadow-color, 0.4);
      border-radius: 0 0 0 8px;
    }
  }

  &__accounts {
    overflow-y: auto;
  }

  .account__display-name {
    &:hover strong {
      text-decoration: none;
    }
  }

  .account__avatar {
    cursor: default;
  }

  .search {
    margin-bottom: 0;
  }
}

.list-adder {
  background: $ui-base-color;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 2px 4px 15px rgba($base-shadow-color, 0.4);
  width: 380px;
  overflow: hidden;

  @media screen and (max-width: 420px) {
    width: 90%;
  }

  &__account {
    background: lighten($ui-base-color, 13%);
  }

  &__lists {
    background: lighten($ui-base-color, 13%);
    height: 50vh;
    border-radius: 0 0 8px 8px;
    overflow-y: auto;
  }

  .list {
    padding: 10px;
    border-bottom: 1px solid lighten($ui-base-color, 8%);
  }

  .list__wrapper {
    display: flex;
  }

  .list__display-name {
    flex: 1 1 auto;
    overflow: hidden;
    text-decoration: none;
    font-size: 16px;
    padding: 10px;
  }
}

.focal-point-modal {
  max-width: 80vw;
  max-height: 80vh;
  position: relative;
}

.focal-point {
  position: relative;
  cursor: pointer;
  overflow: hidden;

  &.dragging {
    cursor: move;
  }

  img {
    max-width: 80vw;
    max-height: 80vh;
    width: auto;
    height: auto;
    margin: auto;
  }

  &__reticle {
    position: absolute;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%);
    background: url('../images/reticle.png') no-repeat 0 0;
    border-radius: 50%;
    box-shadow: 0 0 0 9999em rgba($base-shadow-color, 0.35);
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.floating-action-button {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.9375rem;
  height: 3.9375rem;
  bottom: 1.3125rem;
  right: 1.3125rem;
  background: darken($ui-highlight-color, 3%);
  color: $white;
  border-radius: 50%;
  font-size: 21px;
  line-height: 21px;
  text-decoration: none;
  box-shadow: 2px 3px 9px rgba($base-shadow-color, 0.4);

  &:hover,
  &:focus,
  &:active {
    background: lighten($ui-highlight-color, 7%);
  }
}

.account__header__content {
  color: $darker-text-color;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  word-break: normal;
  word-wrap: break-word;

  p {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.account__header {
  overflow: hidden;

  &.inactive {
    opacity: 0.5;

    .account__header__image,
    .account__avatar {
      filter: grayscale(100%);
    }
  }

  &__info {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  &__image {
    overflow: hidden;
    height: 145px;
    position: relative;
    background: darken($ui-base-color, 4%);

    img {
      object-fit: cover;
      display: block;
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }

  &__bar {
    position: relative;
    background: lighten($ui-base-color, 4%);
    padding: 5px;
    border-bottom: 1px solid lighten($ui-base-color, 12%);

    .avatar {
      display: block;
      flex: 0 0 auto;
      width: 94px;
      margin-left: -2px;

      .account__avatar {
        background: darken($ui-base-color, 8%);
        border: 2px solid lighten($ui-base-color, 4%);
      }
    }
  }

  &__tabs {
    display: flex;
    align-items: flex-start;
    padding: 7px 5px;
    margin-top: -55px;

    &__buttons {
      display: flex;
      align-items: center;
      padding-top: 55px;
      overflow: hidden;

      .icon-button {
        border: 1px solid lighten($ui-base-color, 12%);
        border-radius: 4px;
        box-sizing: content-box;
        padding: 2px;
      }

      .button {
        margin: 0 8px;
      }
    }

    &__name {
      padding: 5px;

      .account-role {
        vertical-align: top;
      }

      .emojione {
        width: 22px;
        height: 22px;
      }

      h1 {
        font-size: 16px;
        line-height: 24px;
        color: $primary-text-color;
        font-weight: 500;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        small {
          display: block;
          font-size: 14px;
          color: $darker-text-color;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .spacer {
      flex: 1 1 auto;
    }
  }

  &__bio {
    overflow: hidden;
    margin: 0 -5px;

    .account__header__content {
      padding: 20px 15px;
      padding-bottom: 5px;
      color: $primary-text-color;
    }

    .account__header__fields {
      margin: 0;
      border-top: 1px solid lighten($ui-base-color, 12%);

      a {
        color: lighten($ui-highlight-color, 8%);
      }

      dl:first-child .verified {
        border-radius: 0 4px 0 0;
      }

      .verified a {
        color: $valid-value-color;
      }
    }
  }

  &__extra {
    margin-top: 4px;

    &__links {
      font-size: 14px;
      color: $darker-text-color;

      a {
        display: inline-block;
        color: $darker-text-color;
        text-decoration: none;
        padding: 10px;
        padding-top: 20px;
        font-weight: 500;

        strong {
          font-weight: 700;
          color: $primary-text-color;
        }
      }
    }
  }
}

.trends {
  &__header {
    color: $dark-text-color;
    background: lighten($ui-base-color, 2%);
    border-bottom: 1px solid darken($ui-base-color, 4%);
    font-weight: 500;
    padding: 15px;
    font-size: 16px;
    cursor: default;

    .fa {
      display: inline-block;
      margin-right: 5px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid lighten($ui-base-color, 8%);

    &:last-child {
      border-bottom: 0;
    }

    &__name {
      flex: 1 1 auto;
      color: $dark-text-color;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      strong {
        font-weight: 500;
      }

      a {
        color: $darker-text-color;
        text-decoration: none;
        font-size: 14px;
        font-weight: 500;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover,
        &:focus,
        &:active {
          span {
            text-decoration: underline;
          }
        }
      }
    }

    &__current {
      flex: 0 0 auto;
      width: 100px;
      font-size: 24px;
      line-height: 36px;
      font-weight: 500;
      text-align: center;
      color: $secondary-text-color;
    }

    &__sparkline {
      flex: 0 0 auto;
      width: 50px;

      path {
        stroke: lighten($highlight-text-color, 6%) !important;
      }
    }
  }
}
